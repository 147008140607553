#root {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  color: rgb(58, 57, 54);
  font-family: 'Crimson Pro', serif;
  font-size:22px;
  line-height: 1.4;
  margin:0;
  padding:10px;

}
h1,h2,h3 {
  font-family: 'Shadows Into Light', cursive;
}
#root h1{
  font-size:1.6em;
  text-align: center;
}
img.logo {
  max-width: 200px;
  float: right;
  margin:0 10px 10px 30px;
}
.subscription-process {
  background-color: #f8f8f6;
  width: 80%;
  max-width: 750px;
  margin:1em;
  border: 1px solid #333;
  border-radius: 4px;
  padding: 15px;
}
.subscription-process h1 {
  margin:0;
}
.back-link {
  margin:0;
  font-size:0.8em;
}
a {
  padding:1px;
  color: #062b82;
}
a:hover {
  text-decoration: none;
  background-color:#efefef;
}

.reading-list h2 {
  margin:0;
  font-size:1.4em;
}

p {
  margin: 10px 0 0;
}

button {
  padding:6px 12px;
  border-radius: 4px;
  margin:0.4em 0;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #337ab7;
  font-family: Arial, sans-serif;
}

@media (max-width: 600px) {
  img.logo {
    float:none;
    width:140px;
    display: block;
    margin: -20px auto 0 auto;
  }
}
